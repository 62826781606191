import { GatsbyImg, IconClock, shortenText } from '@smartproxy-web/ui';
import {
  AuthorBlock,
  AuthorImage,
  BlogImageWrapper,
  BlogInfo,
  BlogTitle,
  ContentWrapper,
  Description,
  NewTopic,
  Topic,
  Topics,
} from './styledComponents';
import { GatsbyLink } from '../GatsbyLink';
import { ArticleBoxProps } from './type';
import { initBuildTrackingId } from '@smartproxy-web/shared/helpers';
import { normalizeTopicPath } from '../BlogParent/utils';
import { useBuildContext } from '../../contexts';
import { translations } from '../BlogPostIntro/constants';
import { logEvent } from '../../helpers';
import { useSliceDetails } from '../../hooks';

export const ArticleBox = ({
  url,
  image,
  isLightTheme = true,
  topics,
  title,
  description,
  date,
  timeToRead,
  author,
  newUntil,
}: ArticleBoxProps) => {
  const { id: sliceId } = useSliceDetails();
  const { lang } = useBuildContext();
  const now = new Date();
  const newUntilDate = newUntil ? new Date(newUntil) : now;
  const isNew = newUntilDate > now;
  const postTrackingSelector = initBuildTrackingId({
    section: 'all-articles',
    context: title.text,
  });
  const topicTrackingSelector = initBuildTrackingId({
    section: 'all-articles',
  });
  const isTopicsAdded = topics && topics.length > 0;
  return (
    <ContentWrapper>
      <BlogImageWrapper
        to={url}
        data-tracker={postTrackingSelector()}
        onClick={() =>
          logEvent({
            sliceId,
            elementId: title.text,
            eventId: postTrackingSelector(),
          })
        }
      >
        <GatsbyImg image={image} />
      </BlogImageWrapper>
      <Topics>
        {isNew && <NewTopic isDark={!isLightTheme}>New</NewTopic>}
        {isTopicsAdded &&
          topics.map((topic) => (
            <Topic
              isDark={!isLightTheme}
              to={`/blog/topics/${normalizeTopicPath(topic)}`}
              key={`${topic}-${Math.random()}`}
              data-tracker={topicTrackingSelector({
                context: `topics-${topic}`,
              })}
              onClick={() =>
                logEvent({
                  sliceId,
                  elementId: topic,
                  eventId: topicTrackingSelector({
                    context: `topic-${topic}`,
                  }),
                  elementGroup: title.text,
                  elementSubGroup: 'topic',
                })
              }
            >
              {topic}
            </Topic>
          ))}
      </Topics>
      <BlogTitle isDark={!isLightTheme} data-tracker={postTrackingSelector()}>
        <GatsbyLink
          to={url}
          onClick={() =>
            logEvent({
              sliceId,
              elementId: title.text,
              eventId: postTrackingSelector(),
            })
          }
        >
          <h4>{title.text}</h4>
        </GatsbyLink>
      </BlogTitle>
      <Description isDark={!isLightTheme}>
        <p>{shortenText(description.text, 100)}</p>
      </Description>
      <AuthorBlock>
        {author?.image && (
          <AuthorImage>
            <GatsbyImg image={author.image} />
          </AuthorImage>
        )}
        {author?.name && (
          <BlogInfo>
            <p>{author.name.text}</p>
          </BlogInfo>
        )}
        <BlogInfo>
          <p>{date}</p>
        </BlogInfo>
        <BlogInfo>
          <IconClock />
          <p>
            {timeToRead} {translations.min_read[lang]}
          </p>
        </BlogInfo>
      </AuthorBlock>
    </ContentWrapper>
  );
};
