import { Badge, Box } from '@smartproxy-web/ui';
import { GatsbyLink } from '../GatsbyLink';
import { useSliceDetails } from '../../hooks';
import { initBuildTrackingId } from '@smartproxy-web/shared/helpers';
import {
  logGAElementClickEvent,
  logPostHogEvent,
} from '@smartproxy-web/analytics';
import { StyledPostTag } from './styledComponents';
import { translations } from './constants';
import { useBuildContext } from '../../contexts';
import { normalizeTopicPath } from '../BlogParent/utils';

export interface PostTopic {
  label: string;
}

interface PostTagsProps {
  isNew: boolean;
  topics: PostTopic[];
}

export const PostTags = ({ isNew, topics }: PostTagsProps) => {
  const { lang } = useBuildContext();
  const { name: sliceName } = useSliceDetails();
  const createTagTrackingID = initBuildTrackingId({
    section: sliceName,
    type: 'link',
  });

  return (
    <Box
      sx={{
        display: 'flex',
        gap: '8px',
        flexWrap: 'wrap',
        marginTop: { base: 22, md: 38, lg: 50 },
      }}
    >
      {isNew && (
        <Badge variant="outlined" size="lg" color="gradient">
          {translations.new[lang]}
        </Badge>
      )}
      {!!topics &&
        topics.map(({ label }) => (
          <PostTag
            key={label}
            label={label}
            trackingId={createTagTrackingID({ context: `${label}-tag` })}
          />
        ))}
    </Box>
  );
};

export const PostTag = ({
  label,
  trackingId,
}: PostTopic & { trackingId: string }) => {
  const { id: sliceId } = useSliceDetails();

  return (
    <GatsbyLink
      to={`/blog/topics/${normalizeTopicPath(label)}`}
      data-tracker={trackingId}
      onClick={() => {
        logPostHogEvent(trackingId);
        if (sliceId) {
          logGAElementClickEvent({ elementId: trackingId, sectionId: sliceId });
        }
      }}
    >
      <StyledPostTag variant="outlined" size="lg" color="gray">
        {label}
      </StyledPostTag>
    </GatsbyLink>
  );
};
